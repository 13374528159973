<template>
<!-- 只能主机 -->
  <div class="Host">
      <div class="hosttop1 hostleft">
                <table class="host_width">
                    <tr class="bg_bule">
                        <td>热平衡</td>
                        <td>状态</td>
                        <td>负荷</td>
                        <td>频率</td>
                        <td>COP</td>
                        <td>功率</td>
                       
                    </tr>
                    <tr class="bg_white">
                        <td>0.00</td>
                        <td>自动</td>
                        <td>0.00 %</td>
                        <td>0.00Hz</td>
                        <td>0.00</td>
                        <td>0.00kW</td>
                       
                    </tr>
                   
                </table>
            </div>
            <div class="hosttop2 hostleft">
                <table class="host_width">
                    <tr class="bg_bule">
                        <td>热平衡</td>
                        <td>状态</td>
                        <td>负荷</td>
                        <td>频率</td>
                        <td>COP</td>
                        <td>功率</td>
                       
                    </tr>
                    <tr class="bg_white">
                        <td>0.00</td>
                        <td>自动</td>
                        <td>0.00 %</td>
                        <td>0.00Hz</td>
                        <td>0.00</td>
                        <td>0.00kW</td>
                       
                    </tr>
                   
                </table>
            </div>
                <div class="hosttop3 hostleft">
                <table class="host_width">
                    <tr class="bg_bule">
                        <td>热平衡</td>
                        <td>状态</td>
                        <td>负荷</td>
                        <td>频率</td>
                        <td>COP</td>
                        <td>功率</td>
                       
                    </tr>
                    <tr class="bg_white">
                        <td>0.00</td>
                        <td>自动</td>
                        <td>0.00 %</td>
                        <td>0.00Hz</td>
                        <td>0.00</td>
                        <td>0.00kW</td>
                       
                    </tr>
                   
                </table>
            </div>
  </div>
</template>
<style src="./Host.css" scoped>

</style>
<script>

export default {
   name:"Host"
}
</script>
