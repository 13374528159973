import { render, staticRenderFns } from "./FreezePump.vue?vue&type=template&id=8d835a38&scoped=true&"
import script from "./FreezePump.vue?vue&type=script&lang=js&"
export * from "./FreezePump.vue?vue&type=script&lang=js&"
import style0 from "./FreezePump.css?vue&type=style&index=0&id=8d835a38&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d835a38",
  null
  
)

export default component.exports