<template>
<!-- 冷冻泵 -->
   <div class="freezePump">
     <div class="freezetop1 freezeleft">
            <table>
                <tr>
                    <td>状态</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td>功率</td>
                    <td>0.0 kW</td>
                   
                </tr>
                <tr>
                    <td>频率</td>
                    <td>0.0 Hz</td>
                  
                </tr>
            </table>
        </div>
       <div class="freezetop2 freezeleft">
            <table>
                <tr>
                    <td>状态</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td>功率</td>
                    <td>0.0 kW</td>
                   
                </tr>
                <tr>
                    <td>频率</td>
                    <td>0.0 Hz</td>
                  
                </tr>
            </table>
        </div>
          <div class="freezetop3 freezeleft">
            <table>
                <tr>
                    <td>状态</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td>功率</td>
                    <td>0.0 kW</td>
                   
                </tr>
                <tr>
                    <td>频率</td>
                    <td>0.0 Hz</td>
                  
                </tr>
            </table>
        </div>
  </div>
</template>
<style src="./FreezePump.css" scoped>

</style>
<script>

export default {
   name:"FreezePump"
}
</script>
