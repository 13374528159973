<template>
<!-- 冷却泵 -->
  <div class="CoolingPump">
     <div class="coolingtop1 coolingleft">
            <table>
                <tr>
                    <td>状态</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td>功率</td>
                    <td>0.0 kW</td>
                   
                </tr>
                <tr>
                    <td>频率</td>
                    <td>0.0 Hz</td>
                  
                </tr>
            </table>
        </div>
       <div class="coolingtop2 coolingleft">
            <table>
                <tr>
                    <td>状态</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td>功率</td>
                    <td>0.0 kW</td>
                   
                </tr>
                <tr>
                    <td>频率</td>
                    <td>0.0 Hz</td>
                  
                </tr>
            </table>
        </div>
          <div class="coolingtop3 coolingleft">
            <table>
                <tr>
                    <td>状态</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td>功率</td>
                    <td>0.0 kW</td>
                   
                </tr>
                <tr>
                    <td>频率</td>
                    <td>0.0 Hz</td>
                  
                </tr>
            </table>
        </div>
  </div>
</template>
<style src="./CoolingPump.css" scoped>

</style>
<script>

export default{
   name:"CoolingPump"
}
</script>
