<template>
<!-- 冷却塔 -->
  <div class="CoolingTower">
      <h2 class="towertitle1">频率:</h2>
      <h2 class="towertitle2">功率:</h2>
       <div class="towertop towerleft1">
            <table>
                <tr>
                    <td>CT1</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td colspan="2">0.0HZ</td>
                </tr>
                  <tr>
                    <td colspan="2">0.0KW</td>
                </tr>
                
            </table>
        </div>
        <div class="towertop towerleft2">
            <table>
                <tr>
                    <td>CT2</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td colspan="2">0.0HZ</td>
                </tr>
                  <tr>
                    <td colspan="2">0.0KW</td>
                </tr>
                
            </table>
        </div>
         <div class="towertop towerleft3">
            <table>
                <tr>
                    <td>CT3</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td colspan="2">0.0HZ</td>
                </tr>
                  <tr>
                    <td colspan="2">0.0KW</td>
                </tr>
                
            </table>
        </div>
          <div class="towertop towerleft4">
            <table>
                <tr>
                    <td>CT4</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td colspan="2">0.0HZ</td>
                </tr>
                  <tr>
                    <td colspan="2">0.0KW</td>
                </tr>
                
            </table>
        </div>
          <div class="towertop towerleft5">
            <table>
                <tr>
                    <td>CT5</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td colspan="2">0.0HZ</td>
                </tr>
                  <tr>
                    <td colspan="2">0.0KW</td>
                </tr>
                
            </table>
        </div>
          <div class="towertop towerleft6">
            <table>
                <tr>
                    <td>CT6</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td colspan="2">0.0HZ</td>
                </tr>
                  <tr>
                    <td colspan="2">0.0KW</td>
                </tr>
                
            </table>
        </div>
          <div class="towertop towerleft7">
            <table>
                <tr>
                    <td>CT7</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td colspan="2">0.0HZ</td>
                </tr>
                  <tr>
                    <td colspan="2">0.0KW</td>
                </tr>
                
            </table>
        </div>
          <div class="towertop towerleft8">
            <table>
                <tr>
                    <td>CT8</td>
                    <td>自动</td>
                  
                </tr>
                <tr>
                    <td colspan="2">0.0HZ</td>
                </tr>
                  <tr>
                    <td colspan="2">0.0KW</td>
                </tr>
                
            </table>
        </div>
  </div>
</template>
<style src="./CoolingTower.css" scoped>

</style>
<script>

export default {
   name:"CoolingTower"
}
</script>
